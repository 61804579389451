import { useApi } from '~/composables/api/useApi'

export function useUser() {
  const { fetchWithAuth } = useApi()
  const config = useRuntimeConfig()
  const RESOURCE = '/user'
  const configAPI = config.public.configAPI

  // GET Requests

  async function fetchUsersById(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${RESOURCE}/${providedOptions.userId}`,
      'GET',
      {}
    )
  }

  async function findUser(providedOptions: {
    mail?: string
    firstName?: string
    lastName?: string
  }) {
    return await fetchWithAuth(`${RESOURCE}/findUser`, 'GET', {
      providedOptions: {
        user: providedOptions.mail,
        firstName: providedOptions.firstName,
        lastName: providedOptions.lastName,
      },
    })
  }

  async function fetchUserConfig(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${configAPI}/user/${providedOptions.userId}`,
      'GET',
      {}
    )
  }

  // POST Requests

  async function updateUserConfig(providedOptions: {
    userId: number
    config: any
  }) {
    return await fetchWithAuth(`${configAPI}/user`, 'POST', {
      data: providedOptions,
    })
  }

  return {
    fetchUsersById,
    findUser,
    fetchUserConfig,
    updateUserConfig,
  }
}
